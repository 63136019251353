<template>
  <div class="my-6 pt-8 pb-10">
    <v-row justify="center" no-gutters>
      <v-col cols="11" lg="10" xl="9">
        <v-row justify="center">
          <h1 class="text-h4 full-width font-weight-bold text-left text-md-center mb-2 px-3 px-md-0">{{ $t('label.buyNowPayLaterBnpl') }}</h1>
          <p class="full-width text-center secondary--text text--lighten-3 mb-lg-12">{{ $t('message.buyNowPayLaterTagline') }}</p>
          <template v-for="(provider, i) in payLaterProviders">
            <v-col cols="11" sm="8" md="4" lg="3" xl="3" :key="`feature_${i}`">
              <v-card class="feature-card rounded-lg" @click="goToExternalLink(provider.externalLink)">
                <div class="px-8 px-xl-12 px-lg-6 pt-8 pb-6 py-md-6">
                  <div class="card-logo__container">
                    <img class="card-logo" :src="provider.logo" :title="provider.name" :alt="provider.tagline" />
                  </div>
                  <div class="text-subtitle-1 font-weight-bold feature-information-subtitle my-4 my-xl-6 my-lg-8">{{ provider.tagline }}</div>
                  <ul class="feature-information-description">
                    <li v-for="(feature, i) in provider.features" :key="i">{{ feature }}</li>
                  </ul>
                </div>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'BuyNowPayLater',
  components: {},
  data: () => ({
    payLaterProviders: [
      {
        name: 'atome',
        logo: '/static/images/payLaterIcons/atome.png?1',
        tagline: 'Own it today with empowered shopping available to me',
        externalLink: 'https://www.atome.my/en-my/how-it-works',
        features: ['Atome helps you own the extraordinary today.', 'Get your items today by splitting your purchases into 3 easy payments.', 'Empower your shopping with control and flexibility.']
      },
      {
        name: 'payLaterByGrab',
        logo: '/static/images/payLaterIcons/grabpaylater.png?1',
        tagline: 'Easy and flexible payments to get what you want now and pay for it later',
        externalLink: 'https://www.grab.com/my/finance/pay-later/',
        features: [
          'Pay in 4 monthly installments or pay the next month in the mighty Grab app and earn GrabRewards for it.',
          'No interest, no hidden charges, and no fees as long as you pay on time.',
          'Shop confidently for products you love with updates at every step of the way.'
        ]
      },
      {
        name: 'payLater',
        logo: '/static/images/payLaterIcons/paylater.png?1',
        tagline: 'Shop smarter with PAYLATER',
        externalLink: 'https://www.paylater.com.my/',
        features: [
          'Experience flexible shopping now like never before.',
          '4 easy, zero-fee installments so you can shop smarter than before.',
          'Experience the best way to enjoy shopping with freedom.'
        ]
      },
      {
        name: 'split',
        logo: '/static/images/payLaterIcons/split.png?1',
        tagline: 'Revolutionary financial solutions so you can pay with confidence',
        externalLink: 'https://paywithsplit.co/',
        features: ['The 1st Shariah Compliant certified Buy Now Pay Later service.', 'Your favorite shops to buy from are closer than before with split installments.']
      }
    ]
  }),
  created() {},
  computed: {},
  watch: {},
  methods: {
    goToExternalLink(link) {
      window.open(link, '_self')
    }
  }
}
</script>
<style lang="scss" scoped>
.feature-card {
  height: 100%;
  width: 100%;
}

.card-logo__container {
  height: 50px;
  // width: 150px;
}

.card-logo {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.feature-information-description {
  padding-left: 1rem;
  > li {
    line-height: 1.5;
    font-size: 0.85rem;
    margin-bottom: 0.4rem;
  }
}
</style>
