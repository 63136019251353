var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-6 pt-8 pb-10" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "11", lg: "10", xl: "9" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "h1",
                    {
                      staticClass:
                        "text-h4 full-width font-weight-bold text-left text-md-center mb-2 px-3 px-md-0",
                    },
                    [_vm._v(_vm._s(_vm.$t("label.buyNowPayLaterBnpl")))]
                  ),
                  _c(
                    "p",
                    {
                      staticClass:
                        "full-width text-center secondary--text text--lighten-3 mb-lg-12",
                    },
                    [_vm._v(_vm._s(_vm.$t("message.buyNowPayLaterTagline")))]
                  ),
                  _vm._l(_vm.payLaterProviders, function (provider, i) {
                    return [
                      _c(
                        "v-col",
                        {
                          key: "feature_" + i,
                          attrs: {
                            cols: "11",
                            sm: "8",
                            md: "4",
                            lg: "3",
                            xl: "3",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "feature-card rounded-lg",
                              on: {
                                click: function ($event) {
                                  return _vm.goToExternalLink(
                                    provider.externalLink
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "px-8 px-xl-12 px-lg-6 pt-8 pb-6 py-md-6",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "card-logo__container" },
                                    [
                                      _c("img", {
                                        staticClass: "card-logo",
                                        attrs: {
                                          src: provider.logo,
                                          title: provider.name,
                                          alt: provider.tagline,
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-subtitle-1 font-weight-bold feature-information-subtitle my-4 my-xl-6 my-lg-8",
                                    },
                                    [_vm._v(_vm._s(provider.tagline))]
                                  ),
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "feature-information-description",
                                    },
                                    _vm._l(
                                      provider.features,
                                      function (feature, i) {
                                        return _c("li", { key: i }, [
                                          _vm._v(_vm._s(feature)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }